<template>
    <div class="m-scratch m-scroll" ref="scroll" @scroll="handleScroll($event)">
        <m-header title="刮刮乐">
            <span slot="right" @click="scratchShow = true">兑奖</span>
        </m-header>
        <div class="scratch_bg">
            <div class="scratch-title">
                <img src="@/assets/scratch/this.png" alt="">
                <span>我的刮刮乐</span>
                <img src="@/assets/scratch/this.png" alt="">
            </div>
            <div class="scratch-content">
                <div class="scratch-item"  v-for="(item, index) in list" :key="index">
                    <div class="gift" v-if="item.type == 1">
                        <img v-if="item.gift.pic" :src="item.gift.pic" alt="">
                        <div class="gift-title">
                            <p class="van-multi-ellipsis--l2">{{item.gift.title}}</p>
                            <span>兑换时间：{{item.receive_time}}</span>
                        </div>
                    </div>
                    <div class="coupon" v-if="item.type == 0">
                        <img v-if="item.coupon.pic.url" :src="item.coupon.pic.url" alt="">
                    </div>
                    <div class="rebate" v-if="item.type == 2" @click="again(item)">
                        <div class="audit">
                            <span class="status" v-if="item.user_rebate.audit_status == 0">审核中</span>
                            <span class="status1" v-if="item.user_rebate.audit_status == 1">已通过</span>
                            <span class="status2" v-if="item.user_rebate.audit_status == -1">未通过</span>
                        </div>
                        <img src="@/assets/scratch/money.png" alt="">
                        <div class="rebate-title">
                            <p class="van-multi-ellipsis--l2">MoobyYoho超级返利</p>
                            <div>返现比例：{{item.rebate.rebate_scale}}%</div>
                            <span>兑换时间：{{item.receive_time}}</span>
                        </div>
                    </div>
                </div>
                <p class="search-no" v-if='noMore'>暂无数据</p>  
                <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
            </div>
        </div>
        <!-- 重新审核 -->
        <van-overlay :show="auditShow" z-index="999">
            <div class="wrapper" @click="auditShow = false">
                <div class="again" @click.stop>
                    <div class="again_title">未通过</div>
                    <div class="again_tip">原因：{{row.user_rebate.refuse_reason}}</div>
                    <div class="again_int">
                        <p>下单平台</p>
                        <input type="text" placeholder="请输入您下单平台名称" v-model="channel">
                    </div>
                    <div class="again_int">
                        <p>手机号</p>
                        <input type="text" placeholder="请输入您的下单手机号" v-model="phone">
                    </div>
                    <div class="again_int">
                        <p>订单金额</p>
                        <input type="text" placeholder="请输入您的订单金额" v-model="amount">
                    </div>
                    <div class="again_btn" @click="handleRebate">重新提交</div>
                </div>
            </div>
        </van-overlay>
        <m-scratch :show.sync="scratchShow" @fatherMethod="getData"></m-scratch>
    </div>
</template>
<script>
import { exchange_list, again } from '@/api/zh/scratch.js'
import MHeader  from '@/components/zh/m-header.vue'
import MScratch     from '../account/m-scratch.vue'

export default {
    components: {
        MHeader,
        MScratch
    },
    data() {
        return {
            page: 1,
            list: [],
            noMore: false,
            loading: false,
            auditShow: false,
            row: {
                user_rebate: ''
            },
            channel: '',
            phone: '',
            amount: '',
            scratchShow: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        handleScroll(e) {
            if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 0){
                if(!this.noMore){
                    e.preventDefault()
                    e.stopPropagation()
                    this.loading = true
                    this.page++
                    this.getData()
                }
            }
        },
        getData() {
            let params = {
                page: this.page
            }
            this.loading = true
            exchange_list(params).then(res => {
                if(res) {
                    this.list = res.data.data
                    if (res.data.data.length == 0) {
                        this.noMore = true
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        again(row) {
            if(row.user_rebate.audit_status == -1) {
                this.row = row
                this.auditShow = true
            }
        },
        handleRebate() {
            this.$store.state.load = true;
            let params =  {
                apply_channel: this.channel,
                apply_phone: this.phone,
                apply_amount: this.amount,
            }
            again(this.row.user_rebate.id ,params).then(res => {
                if(res) {
                    this.$notify({type:'success',message:'提交成功，需审核，请耐心等待~'})
                    this.getData()
                    this.auditShow = false
                }
            }).finally(() => {
                this.$store.state.load = false;
            })
        }
    }
};
</script>
<style scoped lang='less'>
.m-scratch {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f5f5f5;
    padding-top: 52px;
    display: flex;
    justify-content: center;
    .scratch_bg{
        width: 359px;
        height: 100%;
        overflow-y: auto;
        background: url('../../../assets/scratch/bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 16px;
        .scratch-title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            margin: 0 auto 16px;
            img {
                width: 10px;
                height: 10px;
                &:nth-of-type(2) {
                    transform: rotate(180deg);
                }
            }
            span {
                font-size: 20px;
                color: #fff;
                margin: 0 6px;
            }
        }
        .scratch-content {
            height: calc(100% - 40px);
            overflow-y: auto;
        }
        .scratch-item {
            width: 327px;
            height: 94px;
            margin-bottom: 15px;
            background: url('../../../assets/scratch/gift.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 8px;
            overflow: hidden;
            .gift {
                display: flex;
                padding: 12px;
                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 6px;
                    margin-right: 12px;
                }
                .gift-title {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    p {
                        color: #000;
                        letter-spacing: 1px;
                    }
                    span {
                        font-size: 12px;
                        color: #666;
                    }
                }
            }
            .coupon {
                width: 100%;
                height: 100%;
                padding: 1px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .rebate {
                display: flex;
                padding: 12px;
                position: relative;
                .audit {
                    width: 66px;
                    height: 18px;
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 14px;
                    color: #fff;
                    overflow: hidden;
                    &>span {
                        width: 66px;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        display: inline-block;
                    }
                    .status {
                        background:#5FA8FF;
                    }
                    .status1 {
                        background: #31C749;
                    }
                    .status2 {
                        background:#FA7067;
                    }
                }
                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 6px;
                    margin-right: 12px;
                }
                .rebate-title {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    &>p {
                        color: #000;
                        letter-spacing: 1px;
                    }
                    &>div {
                        width: 117px;
                        height: 24px;
                        text-align: center;
                        line-height: 24px;
                        border-radius: 6px;
                        font-size: 14px;
                        color: #fff;
                        background: linear-gradient(90deg,#F5C184 0%,#FD9379 100%);
                    }
                    &>span {
                        font-size: 12px;
                        color: #666;
                    }
                }
                .reset {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
    }
    .search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .again {
        width: 287px;
        height: 451px;
        background: url('../../../assets/scratch/rebate-bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 60px 32px 0 29px;
        color: #fff;
        .again_title {
            font-size: 28px;
        }
        .again_tip {
            font-size: 14px;
        }
        .again_int {
            font-size: 14px;
            margin-top: 11px;
            input {
                width: 220px;
                height: 40px;
                border-radius: 8px;
                border:none;
                margin-top: 4px;
                padding-left: 12px;
                color: #333;
                &::placeholder {
                color: #999;
                font-size: 12px;
                }
            }
        }
        .again_btn {
            width: 220px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: url('../../../assets/scratch/swap_btn.png') no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            color: #B64C1C;
            margin-top: 32px;
        }
    }
}
    
</style>